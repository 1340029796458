import axios from 'axios';
import { store } from '../redux/store';
import { setAccessToken, logout } from '../redux/features/auth/authSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Request interceptor to include token in headers
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor to handle response errors
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        if (data.error === 'Refresh token not found') {
          store.dispatch(logout());
          window.location.href = '/login';
          return Promise.reject(error);
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const response = await api.post('/api/auth/refresh-token');
            const { accessToken } = response.data;

            store.dispatch(setAccessToken(accessToken));
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return api(originalRequest);
          } catch (err) {
            store.dispatch(logout());
            window.location.href = '/login'; 
            return Promise.reject(err);
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
