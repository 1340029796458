import React, { useEffect } from 'react';
import hidden_card from '../../../assets/images/cards/hiden_card1.png';
import { useSelector, useDispatch } from 'react-redux';
import { updatePlayerChips } from '../redux/gameSlice';

// Import sound files
import foldSound from '../../../assets/sounds/Fold.mp3';
import checkSound from '../../../assets/sounds/Check.mp3';
import raiseSound from '../../../assets/sounds/Raise.mp3';
import winnerSound from '../../../assets/sounds/winner.mp3';
import failSound from '../../../assets/sounds/fail.mp3';



const positionClasses = {
  1: 'absolute -bottom-24 left-1/4 transform -translate-x-1/2',
  2: 'absolute -bottom-24 right-1/4 transform translate-x-1/2',
  3: 'absolute -left-12 top-1/2 transform -translate-y-1/2',
  4: 'absolute -right-12 top-1/2 transform -translate-y-1/2',
};

const importCardImage = (cardName1, cardName2) => {
  const cardName = cardName1 || cardName2;
  
  if (!cardName) {
    return hidden_card;
  }
  try {
    return require(`../../../assets/images/cards/${cardName}.png`);
  } catch (err) {
    console.error(`Error loading card image: ${cardName}`, err);
    return hidden_card; // Fallback to a default image
  }
};

// Function to play a sound based on bet type
const playSound = (betType) => {
  let audio;
  switch (betType) {
    case 'fold':
      audio = new Audio(foldSound);
      break;
    case 'check':
      audio = new Audio(checkSound);
      break;
    case 'raise':
      audio = new Audio(raiseSound);
      break;
    case 'winner':
      audio = new Audio(winnerSound);
      break;
    case 'fail':
      audio = new Audio(failSound);
      break;
    default:
      return;
  }
  audio.play();
};


function PlayerPosition({ player }) {
  const dispatch = useDispatch();
  const { status, betPlaced, playerCards, myInfo, winner } = useSelector((state) => state.game);

  useEffect(() => {
    if (player && status === 'starting') {
      if (player.playerRole.includes('small_blind')) {
        const newChips = player.available_chips - 1;
        dispatch(updatePlayerChips({ player_id: player.player_id, new_chips: newChips, bet_amount: 1 }));
      }
      if (player.playerRole.includes('big_blind')) {
        const newChips = player.available_chips - 2;
        dispatch(updatePlayerChips({ player_id: player.player_id, new_chips: newChips, bet_amount: 2 }));
      }
    }
  }, [status, dispatch, player]);

  useEffect(() => {
    if (myInfo && myInfo.player_id && winner !== null && winner !== undefined) {
      const timer = setTimeout(() => {
        if (myInfo.player_id === winner) {
          playSound('winner');
        } else {
          playSound('fail');
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [myInfo, winner]);
  
  


  useEffect(() => {
    betPlaced.forEach((bet) => playSound(bet.bet_type));
  }, [betPlaced]);

  if (!player) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-white">Loading Player Data...</p>
      </div>
    );
  }

  const {
    username = 'Unknown Player',
    user_image = hidden_card,
    available_chips = 0,
    position = 1,
    playerRole = [],
    smallBlind = 1,
    bigBlind = 2,
  } = player;

  const isDealer = playerRole.includes('dealer');
  const isWinner = winner === player.player_id;

  const playerBets = betPlaced.filter((bet) => bet.player_id === player.player_id);

  return (
    <div className={`${positionClasses[position]} flex flex-col items-center transition duration-300 hover:scale-105`}>
      <div className="flex gap-1 mb-2 sm:gap-2">
        {(status === 'playing' || status === 'winner_declared') && myInfo.player_id === player.player_id ? (
          <>
            <img
              key={`${username}-card-1`}
              src={importCardImage(playerCards?.card1, player.card1)}
              alt={`${username}'s Card 1`}
              className="w-16 h-24 object-contain sm:w-24 sm:h-36 shadow-lg"
            />
            <img
              key={`${username}-card-2`}
              src={importCardImage(playerCards?.card2, player.card2)}
              alt={`${username}'s Card 2`}
              className="w-16 h-24 object-contain sm:w-24 sm:h-36 shadow-lg"
            />
          </>
        ) : (
          <>
            {player.card1 && player.card2 ? (
              <>
                <img
                  key={`${username}-card-1`}
                  src={importCardImage(player.card1)}
                  alt={`${username}'s Card 1`}
                  className="w-16 h-24 object-contain sm:w-24 sm:h-36 shadow-lg"
                />
                <img
                  key={`${username}-card-2`}
                  src={importCardImage(player.card2)}
                  alt={`${username}'s Card 2`}
                  className="w-16 h-24 object-contain sm:w-24 sm:h-36 shadow-lg"
                />
              </>
            ) : (
              Array(2)
                .fill()
                .map((_, index) => (
                  <img
                    key={`${username}-card-${index}`}
                    src={hidden_card}
                    alt={status === 'playing' ? `${username}'s Card ${index + 1}` : ''}
                    className={`w-16 h-24 object-contain sm:w-24 sm:h-36 ${status === 'playing' ? '' : 'hidden'}`}
                  />
                ))
            )}
          </>
        )}
      </div>

      <img
        src={user_image}
        alt={username}
        className={`w-12 h-12 rounded-full object-contain sm:w-16 sm:h-16 shadow-md transition duration-300 ${isDealer ? 'border-4 border-yellow-500' : ''}`}
      />

      <p className={`text-white mt-2 text-sm sm:text-base ${isWinner ? 'text-yellow-300 font-bold' : ''}`}>
        {username} {isDealer && '(Dealer)'} {isWinner && '(Winner)'}
      </p>

      {(status === 'starting' || status === 'playing') && !isWinner && (
        <div className="bg-black text-white px-3 py-1 rounded-md text-sm font-bold shadow-md">
          {available_chips}
        </div>
      )}

      {status === 'starting' && (
        <div className="flex flex-col items-center mt-2">
          {playerRole.includes('small_blind') && (
            <div className="bg-gray-800 text-yellow-300 px-2 py-1 rounded-md text-sm font-bold mb-1">
              Bet 1: {smallBlind} Chips
            </div>
          )}
          {playerRole.includes('big_blind') && (
            <div className="bg-gray-800 text-yellow-300 px-2 py-1 rounded-md text-sm font-bold">
              Bet 2: {bigBlind} Chips
            </div>
          )}
        </div>
      )}

      {playerBets.length > 0 && (
        <div className="mt-4">
          <div className="flex flex-col">
            {playerBets.map((bet, index) => (
              <div
                key={index}
                className={`px-2 py-1 rounded-md text-sm font-bold mb-1 ${
                  bet.bet_type === 'fold'
                    ? 'bg-red-500 text-white'
                    : bet.bet_type === 'raise'
                    ? 'bg-green-600 text-white'
                    : bet.bet_type === 'call'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-500 text-white'
                }`}
              >
                {bet.bet_type.charAt(0).toUpperCase() + bet.bet_type.slice(1)}
                {bet.bet_type === 'raise' || bet.bet_type === 'call' ? `: ${bet.amount} Chips` : ''}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayerPosition;
